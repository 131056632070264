<template>
  <div class="range-filter">
    <b-row no-gutters>
      <b-col
        cols="6"
      >
        <b-form-input
          v-if="filter.range_type==='number'"
          v-model="fromRange"
          type="number"
          class="first-input"
          style="border-bottom-left-radius: 0;border-top-left-radius: 0;border-left-width: 0"
          :placeholder="$t('general.from')"
        />
        <flat-pickr
          v-if="filter.range_type==='date'||filter.range_type==='date_time'"
          v-model="fromRange"
          class="form-control first-input"
          :placeholder="$t('general.from')"
          :config="{ enableTime: filter.range_type==='date_time',dateFormat: filter.range_type==='date_time'?'Y-m-d H:i':'Y-m-d'}"
        />
      </b-col>
      <b-col
        cols="6"
      >
        <b-form-input
          v-if="filter.range_type==='number'"
          v-model="toRange"
          type="number"
          class="second-input"
          style="border-bottom-right-radius: 0;border-top-right-radius: 0"
          :placeholder="$t('general.to')"
        />
        <flat-pickr
          v-if="filter.range_type==='date'||filter.range_type==='date_time'"
          v-model="toRange"
          class="form-control second-input"
          :placeholder="$t('general.to')"
          :config="{ enableTime: filter.range_type==='date_time',dateFormat: filter.range_type==='date_time'?'Y-m-d H:i':'Y-m-d'}"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BRow, BCol, BFormInput } from 'bootstrap-vue'

import(/* webpackChunkName: "date-picker" */ '@core/scss/vue/libs/vue-flatpicker.scss')

export default {
  name: 'RangeFilter',
  components: {
    BRow,
    BCol,
    BFormInput,
    flatPickr: () => import(/* webpackChunkName: "date-picker" */ 'vue-flatpickr-component'),

  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    return {
      fromRange: '',
      toRange: '',
      max: maxDate,
    }
  },
  watch: {
    fromRange(val) {
      this.$emit('setFrom', val)
    },
    toRange(val) {
      this.$emit('setTo', val)
    },
  },
}
</script>
