<template>
  <vue-multiselect
    v-model="selected"
    :options="options"
    :multiple="true"
    :track-by="filter.optionsLabel.valueLabel"
    selected-label="✅"
    deselect-label="❌"
    select-label="✔"
    :placeholder="$t(filter.label)"
    :close-on-select="false"
    :clear-on-select="false"
    :internal-search="true"
    :preserve-search="true"
    :searchable="true"
    :label="filter.optionsLabel.translate ? $t(filter.optionsLabel.keyLabel) : filter.optionsLabel.keyLabel"
    @input="setSelected"
  >
    <template
      slot="selection"
      slot-scope="{ values, isOpen }"
    >
      <div
        v-if="values.length && !isOpen"
        class="d-flex align-items-start flex-wrap"
      >
        <span class="multiselect__tag px-1 mr-1">{{ getOptionLabel(values[0]) }}</span>
        <span
          v-if="values.length > 1"
          class="badge badge-secondary small no-wrap d-inline-block d-xxl-none"
        >+{{ values.length - 1 }}</span>
      </div>
      <div
        v-else-if="isOpen"
        class="text-extra-small"
      />
    </template>
    <template
      slot="option"
      slot-scope="{ option }"
    >
      {{ getOptionLabel(option) }}
    </template>
  </vue-multiselect>
</template>

<script>
import(/* webpackChunkName: "multiselect" */ 'vue-multiselect/dist/vue-multiselect.min.css')

export default {
  name: 'MultiSelectFilter',
  components: {
    VueMultiselect: () => import(/* webpackChunkName: "multiselect" */ 'vue-multiselect'),
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    options() {
      const options = this.filter.options ?? []
      if (typeof options === 'function') return this.filter.options()
      return options
    },
  },
  methods: {
    setSelected(value) {
      if (!value) return
      this.$emit('input', value.map(v => v[this.filter.optionsLabel.valueLabel]).join(','))
    },
    getOptionLabel(obj) {
      const label = obj[this.filter.optionsLabel.keyLabel]
      if (this.filter.optionsLabel.translate) return this.$t(label)
      return label
    },
    reset() {
      this.selected = []
    },
  },
}
</script>
