<template>
  <div>
    <flat-pickr
      v-model="date"
      class="form-control"
      :placeholder="$t(filter.label)"
      :config="{ enableTime: filter.type==='date_time',dateFormat: filter.type==='date_time'?'Y-m-d H:i':'Y-m-d'}"
    />
  </div>
</template>

<script>
import(/* webpackChunkName: "date-picker" */ '@core/scss/vue/libs/vue-flatpicker.scss')

export default {
  name: 'DateFilter',
  components: {
    flatPickr: () => import(/* webpackChunkName: "date-picker" */ 'vue-flatpickr-component'),
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    return {
      max: maxDate,
      date: '',
    }
  },
  watch: {
    date(val) {
      this.$emit('input', val)
    },
  },
}
</script>
