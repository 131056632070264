<template>
  <v-select
    v-model="selected"
    dir="rtl"
    input-id="value"
    :options="typeof options === 'function' ? options() : options"
    :placeholder="$t(filter.label)"
    :multiple="filter.multiple"
    :clearable="false"
    class="select-size-lg filter-select"
    @input="setSelected"
  >
    <template
      slot="option"
      slot-scope="option"
    >
      <div class="d-center">
        {{ getOptionLabel(option) }}
      </div>
    </template>
    <template
      slot="selected-option"
      slot-scope="option"
    >
      <div class="selected d-center">
        {{ getOptionLabel(option) }}
      </div>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'SelectFilter',
  components: {
    vSelect,
  },
  props: ['filter'],
  data() {
    return {
      selected: '',
    }
  },
  computed: {
    options() {
      const options = this.filter.options ?? []
      if (typeof options === 'function') return this.filter.options()
      return options
    },
  },
  methods: {
    setSelected(value) {
      if (!value) return
      this.$emit('input', value[this.filter.optionsLabel.valueLabel])
    },
    getOptionLabel(obj) {
      const label = obj[this.filter.optionsLabel.keyLabel]
      if (this.filter.optionsLabel.translate) return this.$t(label)
      return label
    },
    reset() {
      this.selected = ''
    },
  },
}
</script>

<style lang="scss">
.v-select
{
  &.filter-select.select-size-lg {
    .vs__dropdown-toggle {
      padding: 5px 10px 9px !important;
      height: 2.714rem
    }
    .vs__selected {
      font-size: 15px;
      margin-top: 0 ;
    }
    .vs__search {
      font-size: 14px;
      line-height: 24px;
      padding: 0;

      &::placeholder{
        color: #cecece;
        font-size: 14px;
        padding-top: 0;
      }
    }
  }
}

</style>
