<template>
  <div>
    <category-select
      v-if="filter.completeType==='category'"
      ref="select"
      v-model="items"
      :multiple="filter.multiple"
    />
    <item-select
      v-if="filter.completeType==='item'"
      ref="select"
      v-model="items"
      :multiple="filter.multiple"
    />
  </div>
</template>

<script>
import ItemSelect from '@/views/store/products/components/ItemsSelect'
import categorySelect from '@/@bya3/components/inputs/CategorySelect'

export default {
  name: 'AutoCompleteFilter',
  components: {
    ItemSelect,
    categorySelect,
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    items(val) {
      this.$emit('input', val.join(','))
    },
  },
}
</script>
