<template>
  <b-form
    ref="form"
    @submit.prevent="submit"
  >
    <b-row class="table-filters">
      <slot
        ref="extra"
        name="extra-filters"
      />
      <b-col
        v-for="(filter, filterIdx) in filters"
        :key="filterIdx"
        sm="6"
        :md="filter.colLength"
      >
        <b-form-group :label="(filter.preLabel || '') + $t(filter.label)">
          <template v-if="!filter.type || filter.type==='text'">
            <b-input-group>
              <b-form-input
                :id="'input'+filter.query"
                :key="'input'+filter.query"
                v-model="queries[filter.query]"
                :placeholder="$t(filter.label)"
              />

              <b-input-group-append
                v-if="filter.append"
                :is-text="filter.appendType==='text'"
              >
                <b-form-select
                  v-model.trim="queries[filter.appendQuery]"
                  style="border-radius: 0"
                  filter-append-type="=='select'"
                >
                  <b-form-select-option
                    v-for="option in filter.appendOptions"
                    :key="option.value"
                    :value="option.value"
                    v-text="option.text"
                  />

                </b-form-select>
              </b-input-group-append>
            </b-input-group>
          </template>
          <template v-if="filter.type==='autocomplete'">
            <auto-complete-filter
              :id="'input'+filter.query"
              ref="autocompletes_filters"
              :key="'input'+filter.query"
              v-model="queries[filter.query]"
              :filter="filter"
            />
          </template>
          <template v-if="filter.type==='range'">
            <range-filter
              :id="'input'+filter.query"
              :key="'input'+filter.query"
              :filter="filter"
              @setFrom="queries[filter.query+'_from']=$event"
              @setTo="queries[filter.query+'_to']=$event"
            />
          </template>
          <template v-if="filter.type==='select' && filter.options">
            <multi-select-filter
              v-if="filter.multiple"
              :key="'input'+filter.query"
              ref="selectInput"
              v-model="queries[filter.query]"
              :filter="filter"
            />
            <SelectFilter
              v-else
              :key="'input'+filter.query"
              ref="selectInput"
              v-model="queries[filter.query]"
              :filter="filter"
            />
          </template>
          <DateFilter
            v-if="filter.type==='date' || filter.type==='date_time'"
            :key="'input'+filter.query"
            v-model="queries[filter.query]"
            :filter="filter"
          />
        </b-form-group>
      </b-col>
      <b-col
        sm="3"
      >
        <div class="filters-buttons d-flex">
          <b-button
            variant="outline-dark"
            type="submit"
          >
            <feather-icon
              class="mr-50"
              icon="SearchIcon"
            />
            {{ $t('forms.search') }}
          </b-button>
          <b-button
            variant="outline-primary"
            type="button"
            @click="reset"
          >
            <feather-icon
              class="mr-50"
              icon="RefreshCcwIcon"
            />
            حذف الفلاتر
          </b-button>
        </div>
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BForm, BFormSelect, BFormSelectOption,
} from 'bootstrap-vue'

export default {
  name: 'TableFilters',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BFormSelectOption,
    BInputGroupAppend,
    BButton,
    BForm,
    DateFilter: () => import(/* webpackChunkName: "ok-table-filters" */ './filters/DateFilter'),
    RangeFilter: () => import(/* webpackChunkName: "ok-table-filters" */ './filters/RangeFilter'),
    SelectFilter: () => import(/* webpackChunkName: "ok-table-filters" */ './filters/SelectFilter'),
    MultiSelectFilter: () => import(/* webpackChunkName: "ok-table-filters" */ '@/@bya3/components/tables/ok-table/filters/MultiSelectFilter'),
    AutoCompleteFilter: () => import(/* webpackChunkName: "ok-table-filters" */ '@/@bya3/components/tables/ok-table/filters/AutoCompleteFilter'),
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      queries: {},
    }
  },
  mounted() {
    this.initQueries()
  },
  methods: {
    initQueries() {
      this.filters.forEach(filter => {
        if (filter.append) {
          this.queries[filter.appendQuery] = ''
        } else if (filter.type === 'range') {
          this.queries[`${filter.query}_to`] = ''
          this.queries[`${filter.query}_from`] = ''
        } else this.queries[filter.query] = ''
      })
    },
    submit() {
      this.$emit('submit', this.queries)
    },
    reset() {
      this.initQueries()
      this.$refs.form.reset()
      const selects = this.$refs.selectInput
      if (selects && selects.length) selects.forEach(ref => ref.reset())
      const autocompletes = this.$refs.autocompletes_filters
      if (autocompletes && autocompletes.length) autocompletes.forEach(ref => ref.$refs.select.deselectAll())
      this.$store.commit('setup/SET_EXTRA_FILTERS', null)
      this.$emit('submit', this.queries)
    },
  },
}
</script>
<style lang="scss">
  .table-filters {
    margin-bottom: 5px;
    margin-left: -0.4rem !important;
    margin-right: -0.4rem !important;
    & > div {
      padding-right: 5px;
      padding-left: 5px;
    }

    legend {
      font-size: 15px;
      font-weight: 500;
      margin: 0!important;
      padding: 0 0 3px!important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .filters-buttons{
      margin-top: 23px;
      button:not(:first-child){
        margin: 0 5px;
      }
    }

    .form-control{
      font-size: 14px;
    }
  }
</style>
